<template>
  <div>
    <v-row align="end" justify="end">
      <v-col cols="12" sm="12" md="8" lg="6">
        <el-input
          ref="searchBar"
          suffix-icon="el-icon-search"
          v-model="search"
          clearable
          placeholder="Search"
        >
          <template slot="prepend">
            <el-button @click="addForm()"
              ><i class="el-icon-plus"></i>
            </el-button>
          </template>
        </el-input>
      </v-col>
    </v-row>
    <v-col cols="12">
      <v-data-table
        height="65vh"
        dense
        fixed-header
        :search="search"
        :loading="loading"
        :items="forms"
        :headers="headers"
        :calculate-widths="true"
        style="cursor: pointer"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-row justify="center">
            <v-btn small icon @click="editForm(item)"
              ><v-icon small>edit</v-icon></v-btn
            >
            <v-btn small icon @click="deleteForm(item)" color="red"
              ><v-icon small>delete</v-icon></v-btn
            >
          </v-row>
        </template>
      </v-data-table>
    </v-col>

    <v-dialog
      v-model="dialog"
      width="1200px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat dense color="transparent">
          <v-toolbar-title
            >Manage Form
            <span v-if="item.id">| ID: {{ item.id }}</span></v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn text @click="formPreview = true"
            ><v-icon>visibility</v-icon></v-btn
          >
          <v-btn
            :disabled="!item.name"
            text
            color="primary"
            :loading="savingItem"
            @click="saveForm"
            >Save</v-btn
          >
          <v-btn text @click="(dialog = false), (item = {})">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-card-title>
                <v-text-field
                  solo
                  v-model="item.name"
                  placeholder="Form Name"
                ></v-text-field>
              </v-card-title>
              <v-card-subtitle>
                <v-textarea
                  solo
                  v-model="item.description"
                  placeholder="Description"
                ></v-textarea>
              </v-card-subtitle>
            </v-col>
            <v-col cols="12" sm="6">
              <v-list dense>
                <v-subheader
                  >Form Fields
                  <v-btn icon color="primary" @click="addFormItem"
                    ><v-icon>add_circle_outline</v-icon></v-btn
                  ></v-subheader
                >
                <v-list dense style="max-height: 45vh; overflow-y: auto">
                  <draggable
                    group="variations"
                    v-model="item.formItems"
                    @change="updateFormItemIndices"
                  >
                    <v-list-item
                      v-for="(formField, index) in item.formItems"
                      :key="formField.index"
                      style="cursor: pointer"
                    >
                      <v-list-item-action>
                        <v-icon>drag_indicator</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          formField.name
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          formField.value
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{
                          formField.description
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row justify="center">
                          <v-btn icon small @click="editFormItem(formField)"
                            ><v-icon small>edit</v-icon></v-btn
                          >
                          <v-btn
                            icon
                            small
                            color="error"
                            @click="
                              deleteFormItem(formField, index)
                            "
                            ><v-icon small>delete</v-icon></v-btn
                          >
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                  </draggable>
                </v-list>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="fieldModal"
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Manage Field </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(fieldModal = false), (fieldItem = {})">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="my-0 py-0">
              <v-text-field
                label="Field Name"
                outlined
                v-model="fieldItem.name"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <v-text-field
                label="Field Value"
                outlined
                v-model="fieldItem.value"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-select
                :items="['text', 'textarea', 'checkbox', 'selection']"
                v-model="fieldItem.type"
                outlined
                dense
                label="Field Type"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field
                v-if="fieldItem.type == 'text'"
                label="Default Value"
                outlined
                v-model="fieldItem.defaultValue"
                dense
              ></v-text-field>
              <v-textarea
                v-else-if="fieldItem.type == 'textarea'"
                label="Default Value"
                outlined
                v-model="fieldItem.defaultValue"
                dense
              ></v-textarea>
              <v-checkbox
                v-else-if="fieldItem.type == 'checkbox'"
                label="Default Value"
                outlined
                v-model="fieldItem.defaultFlag"
                dense
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-checkbox
                label="Required"
                outlined
                v-model="fieldItem.required"
                dense
              ></v-checkbox>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <v-textarea
                label="Description"
                outlined
                auto-grow
                rows="3"
                v-model="fieldItem.description"
              ></v-textarea>
            </v-col>
            <v-col cols="12" v-if="fieldItem.type == 'selection'">
              <v-list dense subheader>
                <v-subheader
                  >Selection Options
                  <v-btn icon color="primary" @click="addFormItemOption"
                    ><v-icon>add_circle_outline</v-icon></v-btn
                  ></v-subheader
                >
                <v-list dense style="max-height: 25vh; overflow-y: auto">
                  <draggable
                    group="variations"
                    v-model="fieldItem.formItemOptions"
                    @change="updateFormItemOptionIndices"
                  >
                    <v-list-item
                      v-for="option in fieldItem.formItemOptions"
                      :key="option.index"
                      style="cursor: pointer"
                    >
                      <v-list-item-action>
                        <v-icon>drag_indicator</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          option.name
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          option.value
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row justify="center">
                          <v-btn
                          icon
                          @click="fieldItem.defaultValue = option.value"
                          ><v-icon
                            v-if="
                              fieldItem.defaultValue == option.value
                            "
                            color="secondary"
                            >star</v-icon
                          ><v-icon v-else>star_border</v-icon></v-btn
                        >
                          <v-btn icon small @click="editFormItemOption(option)"
                            ><v-icon small>edit</v-icon></v-btn
                          >
                          
                          <v-btn icon small @click="editFormItemOption(option)"
                            ><v-icon small>edit</v-icon></v-btn
                          >
                          <v-btn
                            icon
                            small
                            color="error"
                            @click="
                              deleteFormItemOption(option, option.index - 1)
                            "
                            ><v-icon small>delete</v-icon></v-btn
                          >
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                  </draggable>
                </v-list>
              </v-list>
            </v-col>
          </v-row>
          <v-row class="mt-3" justify="center">
            <v-btn
              text
              color="primary"
              :loading="savingFieldItem"
              :disabled="!fieldItem.name || !fieldItem.value || !fieldItem.type"
              @click="saveFieldItem"
              >Save</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="optionModal"
      width="350px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Manage Field Option </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(optionModal = false), (optionItem = {})">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="my-0 py-0">
              <v-text-field
                label="Name"
                outlined
                v-model="optionItem.name"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <v-text-field
                label="Value"
                outlined
                v-model="optionItem.value"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-3" justify="center">
            <v-btn
              text
              color="primary"
              :loading="savingFieldItemOption"
              :disabled="!optionItem.name || !optionItem.value"
              @click="saveFieldItemOption"
              >Save</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="formPreview"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <FormModal :form="item" @close="formPreview = false" />
    </v-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import FormModal from "../SystemComponents/FormModal.vue";
export default {
  components: {
    draggable,
    FormModal,
  },
  data: () => ({
    dialog: false,
    fieldItem: {},
    fieldModal: false,
    formPreview: false,
    forms: [],
    headers: [
      {
        text: "Action",
        value: "action",
        align: "center",
        width: "20px",

        sortable: false,
      },
      {
        text: "Name",
        value: "name",
        align: "left",
        width: "150px",
      },
      {
        text: "Fields",
        value: "formItems.length",
        align: "left",
        width: "20px",
      },
      {
        text: "Description",
        value: "description",
        align: "left",
      },
    ],
    item: {},
    loading: false,
    optionItem: {},
    optionModal: false,
    savingItem: false,
    savingFieldItem: false,
    savingFieldItemOption: false,
    search: null,
  }),
  created() {
    this.getForms();
  },
  methods: {
    addForm() {
      this.item = {
        formItems: [],
        defaultFlag: false,
      };
      this.dialog = true;
    },
    addFormItem() {
      this.fieldItem = {
        formId: this.item.id ?? null,
        index: this.item.formItems ? this.item.formItems.length + 1 : 1,
        type: "text",
        formItemOptions: []
      };
      this.fieldModal = true;
    },
    addFormItemOption() {
      this.optionItem = {
        formItemId: this.fieldItem.id ?? null,
        index: this.fieldItem.formItemOptions ? this.fieldItem.formItemOptions.length + 1 : 1
      };
      this.optionModal = true;
    },
    deleteForm(item) {
      this.$confirm("Are you sure you want to delete this form?", "Confirm", {
        center: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "plain",
      })
        .then(async () => {
          if (item.id) {
            await this.$API.updateForm({
              id: item.id,
              isActive: false,
              isDeleted: true,
            });
          }
          this.getForms();
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "Delete canceled",
          // });
        });
    },
    deleteFormItem(item, index) {
      this.$confirm("Are you sure you want to delete this field?", "Confirm", {
        center: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "plain",
      })
        .then(async () => {
          if (item.id) {
            await this.$API.updateFormItem({
              id: item.id,
              isActive: false,
              isDeleted: true,
            });
          }
          this.item.formItems.splice(index, 1);
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "Delete canceled",
          // });
        });
    },
    deleteFormItemOption(item, index) {
      this.$confirm("Are you sure you want to delete this field option?", "Confirm", {
        center: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "plain",
      })
        .then(async () => {
          if (item.id) {
            await this.$API.updateFormItemOption({
              id: item.id,
              isActive: false,
              isDeleted: true,
            });
          }
          this.fieldItem.formItemOptions.splice(index, 1);
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "Delete canceled",
          // });
        });
    },
    editForm(item) {
      this.item = item;
      this.dialog = true;
    },
    editFormItem(item) {
      this.fieldItem = item;
      this.fieldModal = true;
    },
    editFormItemOption(item) {
      this.optionItem = item;
      this.optionModal = true;
    },
    async getForms() {
      this.loading = true;
      this.forms = await this.$API.getForms();
      this.loading = false;
    },

    async saveFieldItemOption() {
      this.savingFieldItemOption = true;
      if (this.optionItem.id) {
        await this.$API.updateFormItemOption(this.optionItem);
      } else if (this.fieldItem.id) {
        let result = await this.$API.createFormItemOption(this.optionItem);
        this.optionItem = result;
      }

      let findIndex = this.fieldItem.formItemOptions.findIndex(
        (x) => x.index == this.optionItem.index
      );
      if (findIndex > -1) {
        this.fieldItem.formItemOptions[findIndex] = this.optionItem;
      } else {
        this.fieldItem.formItemOptions.push(this.optionItem);
      }
      this.optionModal = false;
      this.optionItem = {};
      this.savingFieldItemOption = false;
    },
    async saveFieldItem() {
      this.savingFieldItem = true;
      if (this.fieldItem.id) {
        await this.$API.updateFormItem(this.fieldItem);
      } else if (this.item.id) {
        let result = await this.$API.createFormItem(this.fieldItem);
        this.fieldItem = result;
      }

      let findIndex = this.item.formItems.findIndex(
        (x) => x.index == this.fieldItem.index
      );
      if (findIndex > -1) {
        this.item.formItems[findIndex] = this.fieldItem;
      } else {
        this.item.formItems.push(this.fieldItem);
      }
      this.fieldModal = false;
      this.fieldItem = {};
      this.savingFieldItem = false;
    },
    async saveForm() {
      this.savingItem = true;
      if (!this.item.id) {
        let result = await this.$API.createForm(this.item);
        this.forms.push(result);
      } else {
        await this.$API.updateForm(this.item);
        // this.getForms();
      }

      this.savingItem = false;
      this.dialog = false;
    },
    updateFormItemIndices() {
      for (let i = 0; i < this.item.formItems.length; i++) {
        this.item.formItems[i].index = i + 1;
        // console.log(this.item.formItems[i].index)
        if (this.item.formItems[i].id) {
          this.$API.updateFormItem(this.item.formItems[i]);
        }
      }
    },
    updateFormItemOptionIndices() {
      for (let i = 0; i < this.fieldItem.formItemOptions.length; i++) {
        this.fieldItem.formItemOptions[i].index = i + 1;
        // console.log(this.item.formItems[i].index)
        if (this.fieldItem.formItemOptions[i].id) {
          this.$API.updateFormItemOption(this.fieldItem.formItemOptions[i]);
        }
      }
    },
  },
};
</script>
